import { dateRevealConst } from "_shared/constants"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdventCalendarPageComponent } from "./AdventCalendarPageComponent";
import { getVerifyDateCall } from "api/homeCall";
import { VideoComponent } from "./VideoComponent";
import { ProductComponent } from "./ProductComponent";
import { SplashComponent } from "./SplashComponent";

export const Home = () => {
    const { pathname } = useLocation();
    const [videoIsCompleted, setVideoIsCompleted] = useState<boolean>(false);
    const [splashIsCompleted, setSplashIsCompleted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showForm, setShowForm] = useState<boolean>(false);

    useEffect(() => {
        getVerifyDate()
    }, []);


    const setIsLoadingFalse = () => {
        setIsLoading(false)
    }

    const getVerifyDate = async () => {
        const response = await getVerifyDateCall();
        const success = response?.errorCode === 0
        if (success) {
            setShowForm(response.data)
        }
        setTimeout(setIsLoadingFalse, 2000);
    };

    return (
        isLoading ?
            <section>
                <div style={{ backgroundColor: 'white' }}className={splashIsCompleted ? 'splash-container-transition fading' : 'splash-container-transition'}>
                    <SplashComponent setSplashIsCompleted={setSplashIsCompleted} />
                </div>
            </section>
            :
            <section>
                {!showForm
                    ?
                    <AdventCalendarPageComponent dateReveal={dateRevealConst} />
                    :
                    <section>
                        <div style={{ width: '100%' }} className={videoIsCompleted ? 'video-container-transition fading' : 'video-container-transition'}>
                            <VideoComponent setVideoIsCompleted={setVideoIsCompleted} />
                        </div>
                        <div className={!videoIsCompleted ? 'd-none' : ''}>
                            <ProductComponent />
                        </div>
                    </section>
                }
            </section>
    )
}