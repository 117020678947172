import './CountDownReveal.css';
import Countdown, { CountdownRenderProps, CountdownTimeDelta } from 'react-countdown';

export interface CountDownRevealProp {
    revealDate: string;}

export const CountDownReveal = (p: CountDownRevealProp) => {
    const renderer = ({ formatted, completed }: CountdownRenderProps) => {
        return (
            <>
                <div className="countdown-reveal">
                    {!completed ? (
                        <>
                        <div className="d-flex flex-column ai-center">
                            <h6 className=''>{formatted.days} giorni {formatted.hours} ore {formatted.minutes} min {formatted.seconds} sec</h6>
                        </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }

    return (
        <Countdown
            autoStart
            date={p.revealDate}
            renderer={renderer}  />
    )
}