import './App.css';
import { Routes, Route } from "react-router-dom";
import { MainBody, Home, NotFound } from 'components/organisms';
import { paypalInitialOptions, dateRevealConst } from '_shared/constants';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

interface AppProps {
  title: string;
  description?: string
  body?: {
    first: string,
    second?: string
  }
}

export const App = (
  {
    title,
    description,
    body
  }: AppProps) => {

  return (
    <PayPalScriptProvider options={paypalInitialOptions}>
      <div className="App">
        <div id="main">
          <Routes>
            <Route path="/" element={<MainBody />}>
              <Route path="/" element={<Home />} />
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
