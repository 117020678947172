import { useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import ReactPlayer from "react-player";

interface VideoComponentProps {
    setVideoIsCompleted: (x: boolean) => void,
}

export const VideoComponent = ({ setVideoIsCompleted }: VideoComponentProps) => {
    const handleVideoEnd = () => {
        setVideoIsCompleted(true);
    };

    const isMobile = useMediaQuery('(max-width:540px)');
    console.log({ isMobile });

    const desktopVideos = [
        "./Intro/E-Desktop.MOV",
        "./Intro/V-Desktop.MOV",
        "./Intro/A-Desktop.MOV"
    ]
    const mobileVideos = [
        "./Intro/E-Mobile1.MOV",
        "./Intro/E-Mobile2.MOV",
        "./Intro/V-Mobile1.MOV",
        "./Intro/V-Mobile2.MOV",
        "./Intro/A-Mobile1.MOV",
        "./Intro/A-Mobile2.MOV"
    ]
    const RandomizeVideo = (min: number, max: number) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const array = !isMobile ? desktopVideos : mobileVideos;
    const videoIndex = RandomizeVideo(0, array.length - 1);
    const video = array[videoIndex];

    return (
        <div className="video-container">
            <video autoPlay muted playsInline className="bg-vid" onEnded={handleVideoEnd}>
                <source src= {video} type="video/mp4"/>
            </video>
            {/* <ReactPlayer url={video} onEnded={handleVideoEnd} muted={true} className="bg-vid" controls={false} playing={true} volume={0}/> */}

        </div>
    )

}