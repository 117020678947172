interface SplashComponentProps {
    setSplashIsCompleted: (x: boolean) => void,
}

export const SplashComponent = ({ setSplashIsCompleted }: SplashComponentProps) => {
    const handleVideoEnd = () => {
        setSplashIsCompleted(true);
    };

    return (
        <div className="splash-container">
            <video autoPlay muted playsInline className="splash-vid" onEnded={handleVideoEnd}>
                <source src="./splash.mov" type="video/mp4" />
            </video>
        </div>
    )

}