import { ReactPayPalScriptOptions } from "@paypal/react-paypal-js";

// export const BASE_URL_API = "https://localhost:7270"
export const BASE_URL_API = "https://api.nbeaute.com"

export const WEBSITE_URL = "https://www.nbeaute.com/"
export const PRIVACY_URL = `${WEBSITE_URL}privacy-policy/`
export const dateRevealConst = `2023/12/01`

//PAYPAL
export const paypalInitialOptions: ReactPayPalScriptOptions = {
    clientId: "test",
    currency: "CHF",
    intent: "capture",
};