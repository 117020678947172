import { useEffect, useState } from 'react'
import './Quantity.css'

interface CounterProps {
    max?: number,
    onCounterChange: (_:number)=> void
}

export const Counter = ({ max, onCounterChange }: CounterProps) => {
    const [counter, setCounter] = useState(1);
    const theMax = max ?? 0;

    useEffect(()=>{
        onCounterChange(counter)
    },[counter]) 

    //increase counter
    const increase = () => {
        if (counter < theMax || theMax === 0) {
            setCounter(count => count + 1);
        }
    };

    //decrease counter
    const decrease = () => {
        if (counter > 1) {
            setCounter(count => count - 1);
        }
    };

    return (
        <div className="counter">
            <div className="btn__container">
                <img src='./circle-minus-solid.svg' onClick={decrease} className='quantity'></img>
                <span className="counter__output">{counter}</span>
                <img src='./circle-plus-solid.svg' onClick={increase} className='quantity'></img>
            </div>
            {counter === theMax && <h6 className='counter_max'>Hai raggiunto la quantità massima acquistabile</h6>}
        </div>
    );
}