import { BASE_URL_API } from "_shared/constants"
import axios, { AxiosError, AxiosResponse } from "axios"
import { BaseResponse, OrderRequest } from "types"

export const orderCall = async (request: OrderRequest) => {
    return await axios
        .post(`${BASE_URL_API}/Frontend/OrderWithoutPayment`, request)
        .then((response: AxiosResponse<BaseResponse>) => {
            return response.data
        })
        .catch((error: AxiosError<BaseResponse>) => {
            return error.response?.data
                ?? { errorCode: -1, errorMessage: "Errore, riprovare più tardi" } as BaseResponse
        })
} 