import "react-rater/lib/react-rater.css";
import { getDailyProductCall } from "api/homeCall";
import { useEffect, useState } from "react";
import { Product } from "types/models";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
} from "mdb-react-ui-kit";
import { Counter } from "components/molecules/Quantity/Quantity";
import { BASE_URL_API, PRIVACY_URL, WEBSITE_URL } from "_shared/constants";
import { OrderRequest } from "types";
import { orderCall } from "api/orderCall";
import { ResponsiveDialogAdvent } from "components/molecules/Dialog/ResponsiveDialogAdvent";

export const ProductComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<Product>();
    const [privacyChecked, setPrivacyChecked] = useState<boolean>(false)
    const [submitLoading, setSubmitLoading] = useState<boolean>()
    const [submitSuccess, setSubmitSuccess] = useState<boolean | undefined>()
    const [submitMessage, setSubmitMessage] = useState<string | undefined>()

    const inputInitialValue: OrderRequest = {
        name: '',
        surname: '',
        email: '',
        phone: '',
        quantity: 1,
        productId: ''
    }

    const [inputValues, setInputValues] = useState<OrderRequest>(inputInitialValue);

    useEffect(() => {
        getDailyProduct()
    }, []);

    const getDailyProduct = async () => {
        setIsLoading(true)
        const response = await getDailyProductCall();
        setIsLoading(false)
        const success = response?.errorCode === 0
        setData(response?.data)
        setInputValues(x => {
            return { ...x, productId: response?.data.id }
        })
        setSubmitMessage(response?.errorMessage)
    };

    const resetInputs = () => {
        setInputValues(inputInitialValue)
        setPrivacyChecked(false)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setSubmitLoading(true)
        const response = await orderCall(inputValues);
        setSubmitLoading(false)
        const success = response.errorCode === 0

        setSubmitSuccess(success)
        setSubmitMessage(response.errorMessage)

        if (success) {
            resetInputs()
        }
    };
    return (
        <MDBContainer fluid>
            <MDBRow className="justify-content-center">
                {!data ?
                    <MDBCol md="8" lg="6" xl="4">
                        <div className="row justify-content-center">
                            <img className="logo" src="NBLogoAvvento.png"></img>
                            <div className="col-md-12 text-center mb-2">
                                <h6 className="form-title"><br></br>Nouvelle Beauté ti dà il benvenuto<br></br><br></br>
                                    Non c'è alcun prodotto o servizio da visualizzare per la data odierna</h6>
                            </div>
                        </div>
                        <a className='btn btn-advent' href={WEBSITE_URL} target="_blank">Visita il sito web</a>
                    </MDBCol>
                    :
                    <MDBCol md="8" lg="6" xl="4">
                        <div className="row justify-content-center">
                            <img className="logo" src="NBLogoAvvento.png"></img>
                            <div className="col-md-12 text-center mb-2">
                                <h6 className="form-title"><br></br>Nouvelle Beauté ti dà il benvenuto<br></br>
                                    Scegli la quantità che desideri e acquista comodamente online il prodotto del giorno</h6>
                            </div>
                        </div>
                        <MDBCard className="card">
                            <div className="d-flex justify-content-center mt-5">
                                <div className="productTextContainer">
                                    <h6 className="brandName">
                                        {data?.brandName}
                                    </h6>
                                    <h1 className="text-dark productTitle">
                                        {data?.title}
                                    </h1>
                                </div>
                            </div>
                            <div className="bg-image rounded hover-overlay">
                                <MDBCardImage
                                    src={`${BASE_URL_API}/${data?.imgUrl}`}
                                    fluid
                                    style={{
                                        width: "70%",
                                        height: "auto",
                                    }}
                                />
                            </div>
                            <MDBCardBody className="pb-0">
                                <div className="d-flex justify-content-between">
                                    <div className="productTextContainer">
                                        <p className="text-muted">{data?.description}</p>
                                    </div>
                                </div>
                            </MDBCardBody>
                            <MDBCardBody className="pb-0 pt-0">
                                <div className="priceContainer">
                                    <h4 className="price">
                                        <s>{data?.price}</s>
                                    </h4>
                                    <h1 className="discountedPrice">
                                        {data?.discountedPrice}
                                    </h1><h6 className="chfPrice">CHF</h6>
                                </div>
                            </MDBCardBody>
                            <MDBCardBody className="pb-0">
                                <Counter
                                    max={data?.orderQuantityLimit}
                                    onCounterChange={(quantity) => setInputValues(x => {
                                        return { ...x, quantity }
                                    }
                                    )} />
                            </MDBCardBody>
                            <div className="contact-wrap w-100 p-md-5 p-4 py-5">
                                <form id="contactForm" className="contactForm" onSubmit={handleSubmit} >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="order-title">Nome</h6>
                                            <div className="form-group">
                                                <input type="text" className="order-inputField" name="name" id="name" placeholder="Inserisci il tuo nome"
                                                    required value={inputValues.name} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <h6 className="order-title ">Cognome</h6>
                                            <div className="form-group">
                                                <input type="text" className="order-inputField" name="surname" id="surname" placeholder="Inserisci il tuo cognome"
                                                    required value={inputValues.surname} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <h6 className="order-title ">Indirizzo e-mail</h6>
                                            <div className="form-group">
                                                <input type="email" required className="order-inputField" name="email" id="email"
                                                    placeholder="Inserisci la tua e-mail" value={inputValues.email} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <h6 className="order-title ">Numero di cellulare</h6>
                                            <div className="form-group">
                                                <input type="tel" pattern="(([\+]|00)[0-9]{2}){0,1}[0-9]{8,10}" className="order-inputField" name="phone"
                                                    id="phone" placeholder="Inserisci il tuo numero di cellulare" required value={inputValues.phone} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group-checkbox">
                                                <input type="checkbox" id="privacy" name="privacy" required checked={privacyChecked}
                                                    onChange={() => setPrivacyChecked(x => !x)} />
                                                <label className="order-title order-checkbox form-control checkbox"
                                                    htmlFor="privacy">Ho letto e accetto l'informativa sulla <a href={PRIVACY_URL} className="order-title order-checkbox">privacy policy</a></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="buttonContainer pb-2 mb-4">
                                        <button className="btn btn-advent">
                                            Conferma ordine e paga al ritiro
                                            {submitLoading && <i className="ml-2 fa fa-spinner fa-pulse fa-2xl"></i>}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </MDBCard>
                    </MDBCol>
                }
            </MDBRow>
            <ResponsiveDialogAdvent
                open={submitSuccess !== undefined}
                message={submitMessage}
                success={submitSuccess}
                onClose={() => setSubmitSuccess(undefined)}
            />
        </MDBContainer >
    );
}

