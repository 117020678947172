import axios, { AxiosResponse } from "axios"

interface KeyValues {
    [key: string]: string | number | boolean;
}

const manageCallResponse = async <TResponse>(call: Promise<AxiosResponse<TResponse>>) => {
    return await call.then((response) => {
        return response.data
    })
        .catch((err) => {
            return undefined
        })
}

export const postCall = async <TRequest, TResponse>(url: string, request: TRequest, params?: KeyValues, headers?: KeyValues) =>
    manageCallResponse(
        axios.post<TResponse>(url, request, { params, headers: headers })
    )

export const getCall = async <TResponse>(url: string, params?: KeyValues, headers?: KeyValues) =>
    manageCallResponse(
        axios.get<TResponse>(url, { params, headers: headers })
    )